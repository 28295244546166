import { GenericDataUnitEnum, GenericDataUnit, FlipDataUnit} from './generic.model';
import { Action } from '@ngrx/store';

const INSERT = 'insert';
const FINSERT="flipinsert";



class GenericAction implements Action {
   readonly type = INSERT;
   constructor(public payload: GenericDataUnit) { }
}

class FlipAction implements Action {
   readonly type = FINSERT;
   constructor(public payload: FlipDataUnit) { }
}


type Actions = GenericAction;

export function GenericReducer(State = {}, action: Actions) {
   const actions =  [INSERT];
   return actions.includes(action.type)? action.payload: {};

};
export function FlipCardReducer(State = {}, action: FlipAction) {
   const actions =  [FINSERT];
   if (actions.includes(action.type)) {
     return action.payload;
   }
   return  {};

};
export function FlipActionWrapper (store: any, action:any, id:any) {
  store.dispatch( new FlipAction ({action: action, id:id, typeof:'FlipDataUnit'}));
}
export function GenericSlideCountTrain (store: any) {
  store.dispatch( new GenericAction ({mode:'count',action: GenericDataUnitEnum.trainSlideSetting,typeof:'GenericDataUnit'}));
}
export function GenericSlideLearnTrain (store: any) {
  store.dispatch( new GenericAction ({mode:'learn',action: GenericDataUnitEnum.trainSlideSetting,typeof:'GenericDataUnit'}));
}
export function GenericSlideBetTrain (store: any) {
  store.dispatch( new GenericAction ({mode:'bet',action: GenericDataUnitEnum.trainSlideSetting,typeof:'GenericDataUnit'}));
}
export function GenericSlideConceptTrain (store: any) {
  store.dispatch( new GenericAction ({mode:'concept',action: GenericDataUnitEnum.trainSlideSetting,typeof:'GenericDataUnit'}));
}

export function GenericSingleCardTrain (store: any) {
  store.dispatch( new GenericAction ({mode:'singleCard',action: GenericDataUnitEnum.trainSlideSetting,typeof:'GenericDataUnit'}));
}

export function GenericSingleSpeedTrain (store: any) {
  store.dispatch( new GenericAction ({mode:'singleSpeed',action: GenericDataUnitEnum.trainSlideSetting,typeof:'GenericDataUnit'}));
}


export function GenericSettingRestoreFromCach (store: any) {
  store.dispatch( new GenericAction ({mode:'none',action: GenericDataUnitEnum.restoreFromCach,typeof:'GenericDataUnit'}));
}
export function GenericSlidePlayTrain (store: any) {
  store.dispatch( new GenericAction ({mode:'play',action: GenericDataUnitEnum.trainSlideSetting,typeof:'GenericDataUnit'}));
}
export function GenericSlideOnlineTrain (store: any) {
  store.dispatch( new GenericAction ({mode:'online',action: GenericDataUnitEnum.trainSlideSetting,typeof:'GenericDataUnit'}));
}
export function GenericSlideLicenseTrain (store: any) {
  store.dispatch( new GenericAction ({mode:'license',action: GenericDataUnitEnum.trainSlideSetting,typeof:'GenericDataUnit'}));
}
export function GenericAddCardCount (store: any) {
  store.dispatch( new GenericAction ({mode:'None',action: GenericDataUnitEnum.addCardCount,typeof:'GenericDataUnit'}));
}
export function GenericUpdateRule (store: any, rule:string) {
  store.dispatch( new GenericAction ({mode:'None',action: GenericDataUnitEnum.updateRule,value:rule, typeof:'GenericDataUnit'}));
}
export function GenericRemoveCardCount (store: any) {
  store.dispatch( new GenericAction ({mode:'None',action: GenericDataUnitEnum.removeCardCount,typeof:'GenericDataUnit'}));
}
export function GenericSettingTopTopEnabled (store: any) {
  store.dispatch( new GenericAction ({action: GenericDataUnitEnum.toptopEnabled,typeof:'GenericDataUnit'}));
}
export function GenericSettingTopTopDisabled (store: any) {
  store.dispatch( new GenericAction ({action: GenericDataUnitEnum.toptopDisabled,typeof:'GenericDataUnit'}));
}
export function GenericSettingDone (store: any) {
  store.dispatch( new GenericAction ({action: GenericDataUnitEnum.settingDone,typeof:'GenericDataUnit'}));
}


export function GenericTimerAction (store: any, value) {
  store.dispatch( new GenericAction ({action: GenericDataUnitEnum.timer,value: value, typeof:'GenericDataUnit'}));
}
export function GenericTimerResetAction (store: any, value=0) {
  store.dispatch( new GenericAction ({action: GenericDataUnitEnum.timerReset,value: value, typeof:'GenericDataUnit'}));
}

export function GenericCardStrengthAction (store: any, value) {
  store.dispatch( new GenericAction ({action: GenericDataUnitEnum.strength,value: value, typeof:'GenericDataUnit'}));
}

export function GenericDieAction (store: any) {
  store.dispatch( new GenericAction ({action: GenericDataUnitEnum.die,typeof:'GenericDataUnit'}));
}
export function GenericGotoPrevAction (store: any) {
  store.dispatch( new GenericAction ({action: GenericDataUnitEnum.gotoPrev,typeof:'GenericDataUnit'}));
}
export function GenericDisableSettingAction (store: any) {
  store.dispatch( new GenericAction ({action: GenericDataUnitEnum.disableSetting,typeof:'GenericDataUnit'}));
}
export function GenericResetAction (store: any) {
  store.dispatch( new GenericAction ({action: GenericDataUnitEnum.reset,typeof:'GenericDataUnit'}));
}
export function GenericGresetAction (store: any) {
  store.dispatch( new GenericAction ({action: GenericDataUnitEnum.greset,typeof:'GenericDataUnit'}));
}
export function GenericConfigActionEnable (store: any, config:string) {
  store.dispatch( new GenericAction ({action: GenericDataUnitEnum.config_enable,typeof:'GenericDataUnit', id:config}));
}
export function GenericConfigActionDisable (store: any, config:string) {
  store.dispatch( new GenericAction ({action: GenericDataUnitEnum.config_disable,typeof:'GenericDataUnit', id:config}));
}

export function GenericDieSlaveAction (store: any, config:string) {
  store.dispatch( new GenericAction ({action: GenericDataUnitEnum.dieSlave,typeof:'GenericDataUnit', id:config}));
}
export function GenericPauseConfigAction (store: any, config:string) {
  store.dispatch( new GenericAction ({action: GenericDataUnitEnum.pause,typeof:'GenericDataUnit', id:config}));
}
export function GenericTopTopAction (store: any, msg:Array<string>, image='tigger') {
  store.dispatch( new GenericAction ({action: GenericDataUnitEnum.msg,typeof:'GenericDataUnit', msg:msg, image:image}));
}

export function GenericStopAction (store: any) {
  store.dispatch( new GenericAction ({action: GenericDataUnitEnum.stop,typeof:'GenericDataUnit', id:'0'}));
}
export function GenericTopTopActionDisable (store: any) {
  store.dispatch( new GenericAction ({action: GenericDataUnitEnum.disable,typeof:'GenericDataUnit', msg:[], image:''}));
}
export function GenericTopTopActionEnable (store: any) {
  store.dispatch( new GenericAction ({action: GenericDataUnitEnum.enable,typeof:'GenericDataUnit', msg:[], image:''}));
}
