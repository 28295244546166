import { Component, ChangeDetectionStrategy, OnInit,OnDestroy, Input, Output, EventEmitter} from '@angular/core';
import { DealerService, CardCount } from '../../../services/deck.service';
//import { MatMenuModule} from '@angular/material/menu';
import { Store } from '@ngrx/store';
import { filter, tap } from 'rxjs/operators';

import { isJack, globalVars,getImage,maxCardsPerCol, MaxCardsOut, downSizeImage, upSizeImage} from '../../../services/global.service';
import { Subscription } from 'rxjs';
import { MobileDrawCardDataUnit, MobileCountCardDataUnit, MobileDrawCardEnum} from '../../store/cards.model';
import {MobileInsertCardAction,MobileInsertBadgeAction, MobileCardsPeerErase } from '../../store/cards.action.reducer';
import {MobileSendKeyRoute,MobileSendUnFlash} from '../../store/bottom.action.reducer';

@Component({
  selector   : 'mobile-cards-display',
  templateUrl: './cards.component.html',
  styleUrls: ['../../styles.scss'],
  // host: {
  //   '(@clist.done)': 'Clist($event)',
  // },
//  changeDetection: ChangeDetectionStrategy.OnPush,
  })


export class MobileCardsComponent implements OnInit, OnDestroy {
  enabled=[];
  subscriptions=[];
  @Output() event = new EventEmitter(); //currently only one event type raised when all the cards are dropped
  @Input() id =0;
  count: number;
  list=[];
  cardsMargin = 'margin-bottom-';
  sendQueue=[];
  cardsSend=0;
  firstSend=true;
  cols=0
  constructor(public dealerService: DealerService,    public store: Store<any>) {}

// reset() {
//   this.dealerService.shuffle();
//   this.list=[];
// }

 send(list:any):void {
   // console.log(`going to send cards: ${JSON.stringify(list)}`);
   // console.log(`npanels:${globalVars['npanels']}, list:${JSON.stringify(list)}, enabled-length:${this.enabled.length}`);
   const timeout=this.firstSend?0:200;
   this.firstSend=false;
   setTimeout(() => {
     this.enabled.push(true);
     MobileInsertCardAction(this.store, list,globalVars['npanels']++);
   }, timeout);

 }
/////////////////////////////////////////
 requestBadge():void {
   let timeout=0;
   for (let target=0; target!=globalVars['npanels'];target++) {
     setTimeout(() => {
       MobileInsertBadgeAction(this.store, target);
     }, timeout);
     timeout+=2000;
   }
 }

 getcount(service:any, last:any) {
   let count = service.getCount();
   if (!['jack', 'single','advance'].includes(globalVars['method'])) {
     return count;
   }
   const mode = globalVars['mode'];
   // if (globalVars['netcount'] || mode.includes('Net') || mode.includes('net') || mode.includes('All') || mode.includes('icense')) {
   //   const reducedCount = ;
   //   if ((reducedCount != count) && last) {
   //     count +=`/${reducedCount}`;
   //   }
   // }
   const includeReduceCount = mode.includes('Net') || mode.includes('net') || mode.includes('Continuous') || mode.includes('icense') || globalVars['netcount'];
   // console.log(`includeReduceCount:${includeReduceCount}, mode:${mode}`)
   if (includeReduceCount && last) {
      return `${count}/${service.getReducedCount()}`;
   }
   return count;
 }
erase() :void{
  // let timeout=0;
  // for (let i=globalVars['npanels']; i>0; i--) {
  //   setTimeout(() => {
  //     MobileCardsPeerErase(this.store, i-1);
  //   }, timeout);
  //   // timeout+=1000;
  //   timeout+=1500;
  //
  // }
    MobileCardsPeerErase(this.store, globalVars['npanels']-1);
  // globalVars['npanels']=0;
}


/////////////////////////////////////

  ngOnInit() :void{
    // MobileSendKeyRoute(this.store,[ {key:"switch"}]);
    this.cardsMargin += globalVars['netcount']? '10': '5';
    // MobileCardsBackgroundOff(this.store);

    const service = this.dealerService;
    // service.shuffle();
    globalVars['npanels'] = 0;

    if (globalVars['mobileDevice']) {
      if (globalVars['license'] || globalVars['mode'] == 'license') {
         downSizeImage();
      }
    }
    const image = globalVars['image'];
    this.cols=8;
    if (globalVars['desktop']) this.cols=8;
    else if (globalVars['mobileDevice']) this.cols=5;


    this.subscriptions.push(
            this.store
              .select('mobileCard')
              .pipe(filter(event=> event.typeof=== 'MobileDrawCardDataUnit' && this.id == event.id))
              .subscribe((data: MobileDrawCardDataUnit) => {
                 // console.log(`train::cards.component the data:${JSON.stringify(data)}`);
                if (data.type == MobileDrawCardEnum.badge) {
                  this.requestBadge();
                  return;
                } else if (data.type == MobileDrawCardEnum.reset) {
                  // console.log(`reset request npanels:${globalVars['npanels']-1}`)
                  MobileCardsPeerErase(this.store, globalVars['npanels']-1);
                  return;

                } else { // first row of the cards to be managed the rest is displayted when first row are displayed

                      const cardsOut=globalVars['cards-out'];
                      const notEnoughCardsLeft = service.cardsLeftInDeck() < cardsOut;
                      const maxCardsPercol = this.cols;
                      if (notEnoughCardsLeft || data['shuffle'] ) {
                        // console.log(`cards-out from deck:${service.outOfDeck()}, shuffled:${service.shuffled()}, cardsOut;${cardsOut}`);
                        if (!service.shuffled()) {
                          service.shuffle();
                          if (notEnoughCardsLeft) {
                            this.event.emit('shuffled');
                            return;
                          }
                        }
                      }
                      this.count = 0;
                      // console.log(`cards.comp cardsOut:${cardsOut}, ${JSON.stringify(image)}`)

                      for(let i=0; i!= cardsOut; i++) {
                        let card=getImage(service.singleCard(1));
                        const _count  = this.getcount(service, (i+1)==cardsOut);
                        this.list.push({card: card, badge: true, value: _count});
                        if (isJack() && globalVars['play']==false) {
                          this.transfer();
                        }
                      }
                      this.cardsSend = cardsOut>this.cols? this.cols: cardsOut;
                      this.send(this.list.splice(0,this.cols));
              }
                setTimeout(() => {
                  MobileSendUnFlash(this.store, 'all');
                }, 300);
              })
            );
  }

  transfer() { // take out all the L or H from the count so only the last count contains the label 'L' or 'H'
        const length=this.list.length;
        let lcount='';
        let l=0;
        for (let e of this.list) {
          if (++l == length) continue; // escape the last element
          const count=e['value'];
          if (count.includes('o')) {
             return;
          }
          const key=count.includes('L')? 'L': 'H';
          lcount=key;
           e['value']=e['value'].replace(lcount, '');
         }
         // this.list[length-1]['value']= `${this.list[length-1]['value']}${lcount}`;
  }
  // event on clist
  // Clist(event: AnimationEvent) {
  //   const ignore = this.cardList.shift();
  // }
  cevent(event:any):void {
    const image = globalVars['image'];
    switch(event.action) {
      case 'drawn': {
            this.count++;
            if (this.count==this.cardsSend) {
              this.count = 0;
              const length = this.list.length;
              if (length) {
                const slice = length < this.cols ? length : this.cols;
                this.cardsSend=slice;
                this.send(this.list.splice(0,slice));
              } else {
                this.event.emit('drawn');
              }
          } break;
      } case 'removed' : {

          this.enabled.pop();
          globalVars['npanels']-=1;
          if (this.enabled.length==0) {
             this.event.emit('removed');
          } else {
              MobileCardsPeerErase(this.store, globalVars['npanels']-1);
          }
          break;
      }
    }
  }

  ngOnDestroy() :void{
    for (const subs of this.subscriptions) {
      subs.unsubscribe();
    }
    delete globalVars['npanels'];
    // globalVars['cards-out']=3;
    if (globalVars['mobileDevice']) {
      if (globalVars['license'] || globalVars['mode'] == 'license') {
         upSizeImage();
      }
    }
    // this.dealerService.shuffle();
  }
}
