import { Component, OnInit, Input } from '@angular/core';
import {trigger,state,style,transition,animate} from '@angular/animations';
@Component({
  selector: 'anms-thumb',
  templateUrl: './component.html',
  styleUrls: [],
  animations:[
    trigger('rotateUp', [
    transition('void=>*',[style({transform:'rotate(0deg)'})]),
    transition ('* => *', [
      style({ transform: 'rotate(0deg)' }),
         animate ('600ms',style ({ transform: 'rotate(-25deg)' }))
    ])
  ]),
  trigger('rotateDown', [
  transition('void=>*',[style({transform:'rotate(0deg)'})]),
  transition ('* => *', [
    style({ transform: 'rotate(0deg)' }),
       animate ('600ms',style ({ transform: 'rotate(25deg)' }))
  ])
])
]

})



export class ThumbComponent implements OnInit {

  @Input() label='up';
  @Input() color='accent';
  icon='';
  value=-1;
  _value=0;
  constructor() { }

   incr() {
    this._value++;
  }
done() {
  setTimeout(() => {
      this.value++;
  }, 200);

}
///////////////////////
  ngOnInit():void {
        this.icon=this.label=='up'? 'thumb_up': 'thumb_down';
  }

}
