import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ANIMATE_ON_ROUTE_ENTER } from '@app/core';
import { timer } from 'rxjs';
import {MobileSendKeyRoute, BottomAppleModify} from '../store/bottom.action.reducer';
// import {GenericResetAction, GenericConfigAction,GenericTopTopAction, GenericDieSlaveAction} from '../store/generic.action.reducer';
import {GenericResetAction, GenericConfigActionEnable, GenericConfigActionDisable, GenericTopTopAction, GenericDieSlaveAction} from '../store/generic.action.reducer';
import { Store } from '@ngrx/store';
import { CommonListAnimations} from '../shared/animations/mobile-animations';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import {MobileRouterService, globalVars} from '../../services/global.service';
import {mainBottomSwitchActionNone} from '../store/bottom.action.reducer';
import { GenericDataUnitEnum, GenericDataUnit} from '../store/generic.model';
import {  map, filter } from 'rxjs/operators';


@Component({
  selector: 'anms-mobile-about',
  templateUrl: './about.component.html',
  animations: [
    CommonListAnimations.drop
  ]
})
export class AboutComponent implements OnInit, OnDestroy {
  @ViewChild('slickModal') slickModal: SlickCarouselComponent;
  count: number;
  subscriptions= [];
  //['auto', 'video-base', 'video-cardcount', 'video-scale']

  // mode="auto";
  mode="video-first";
  // mode="video-second";
  // mode="video-cardcount";
  // mode="video-scale";
   mouseclass=[];

  size={auto:6, 'video-first': 6, 'video-second': 5, 'video-cardcount': 2, 'video-scale':2};
  demoPage=6;
  slideConfig= {
   "slidesToShow": 1,
   "slidesToScroll": 1,
   "dots": false,
   "infinite": false,
   arrows: false,
   'swipeToSlide': false,
 };
 top=300;
 // video=false;
 images={
   num: {
     low:'Five',
     high: 'Five',
     ignored: 'Three'
   },
   low:[
   '/assets/cards/2C',
   '/assets/cards/3D',
   '/assets/cards/4H',
   '/assets/cards/5S',
   '/assets/cards/6C'
  ],
  high:['/assets/cards/10C',
        '/assets/cards/JD',
        '/assets/cards/QH',
        '/assets/cards/KS',
        '/assets/cards/AC'],
  ignored:['/assets/cards/7C',
              '/assets/cards/8D',
              '/assets/cards/9S'],
      };
 hidden_card='/assets/cards/Gray_back_80x100.png';
 vimages={
    1: ['/assets/cards/10H_100x120.png', '/assets/cards/KH_100x120.png'],
    2: ['/assets/cards/6H_100x120.png', '/assets/cards/3S_100x120.png'],
    3: ['/assets/cards/10H_100x120.png', '/assets/cards/6H_100x120.png'],
    4: ['/assets/cards/4H_100x120.png', '/assets/cards/JH_100x120.png'],

 };

 disabled={next:false, backward:true};
  mobile='';
  interval:any;
  current={page:0, dir:'up'};
  classes=[];
  intervalEnabled=true;
  die=false;
  slaveDie=false;
  landing_index = 0;
  state="None"
  // toptopEnable={1:true, 2: true, 3: true, 4: true};
  button='start';
  delay=1200;
  page=0;
  columns=['first-card', 'second-card', 'likelihood'];
  sources=[
    {'first-card': 'high-card', 'second-card': 'high-card', likelihood: '25%'},
    {'first-card': 'low-card', 'second-card': 'low-card', likelihood: '25%'},
    {'first-card': 'high-card', 'second-card': 'low-card*', likelihood: '25%'},
    {'first-card': 'low-card', 'second-card': 'high-card*', likelihood: '25%'},

  ];
  scale={enable:false, img:'', left:0};
  // list=['Card counting concept', 'Three variations of card counting','Train yourself at your own pace','Test yourself','Certification', "online-assist"];
  list=['Three cards counting courses','Train yourself at your own pace','Get certified','Sign up to get a one day free pass'];

  gvars=globalVars;

  constructor(private store:Store<any>,private router: MobileRouterService ) {}

  buttonDisabled=false;
  cells=false;

  factors=[
          "Most player's betting strategy is unreasonable",
          "Dealer's hand are not exposed",
          "Players are first to make a mistake",
          "Players are not properly trained to make a right decision",
          "The emotional toll caused by losses",
          "The house has an unlimited chips, unlike players"
  ];
  fheader=['Blackjack challenge', '25 vs 50 percent odds', 'Lets Make it fair'];
  flaw=['First rule of blackjack','High cards and low cards are balanced', 'Five on each side'];
  slaw=['Second rule of blackjack','Dont panic when dealer face card is a 10', 'Hidden card high/low 50%'];
  cmsg = { // card messages
    0: ['First rule:','High cards and low cards are balanced', 'Five on each side'],
    1: ['Only high and low cards'],
    2: ['2 unique combinations', ['2 non-unique combinations']],
    3: ['3 unique combinations', 'Mix cards 50%'],
    4: ['Prediction overview', "Balanced low and high cards"]
  };

  headers_1 = [
     "Your odds of winning is at 25%",
     "Guessing the dealer's hidden card",
     "You are the first to make a decision",
     "Majoirty of the first 2 cards dealt are mixture of high and low cards(12-16)",
     "Running out of money before the house"
  ];
  headers = [
     "Player odds at 25% versus the house at 75%",
     "Not understanding the cards",
     "Guessing game, too many decisions to be made",
     "Too many distractions"
  ];

  highCards=5;
  lowCards=5;
  // highLowRatio='1.00';
  // lowHighRatio='1.00';
  // cards = {
  //   high:5,
  //   low:5
  // }



  GenericConfigAction(store, index) {
    const start = this.mode == "auto"? 1: 5;
    for(let i=start; i!=start+4;i++) {
      if (index != i) GenericConfigActionDisable(store, `${i}`);
    }
    GenericConfigActionEnable(store, `${index}`);

  }
  start() {

   const delays = {
     0: 20000,
     1: 15000
   };
   this.page+=1;
   this.landing_index +=1
   if (this.page) {
     setTimeout(() => {
       if ( this.page==(this.size['auto'])) {
            clearInterval(this.interval);
            this.page = 0;
            this.landing_index = 0;
            return;
       }
       this.GenericConfigAction(this.store, `${this.landing_index}`);
       this.slickModal.slickGoTo(this.page);
       this.start();
     }, delays[1]);

     return;
   }

   setTimeout(() => {
     this.GenericConfigAction(this.store, `${this.landing_index}`);
     this.slickModal.slickGoTo(this.page);
     this.start();
   }, delays[0]);
  }

  forwardClickedPage1() {
    this.page+=1;
    this.disabled.backward=false;
    this.state='None';
    if (this.page == this.size['video-first']) {
      this.page=0;
      this.mode="video-second";
      // GenericTopTopAction(this.store, this.cmsg[0]);
      return;
    }
    this.GenericConfigAction(this.store, `${this.page+4}`);
    this.slickModal.slickGoTo(this.page);

  }
  backwardClickedPage1() {
    this.page-=1;
    this.disabled.backward=this.page?false:true;
    if (this.page==0) {
      GenericTopTopAction(this.store,this.fheader);
      return;
    }
    let delay=this.delay+100;
    let page= this.page+4; // the first demo-id is at 5
     GenericDieSlaveAction(this.store, `${page+1}`); // erase previous-page
     GenericDieSlaveAction(this.store, `${page}`); // erase current-page
    setTimeout(() => {
      this.GenericConfigAction(this.store, `${page}`);
    }, this.delay+100);

   this.slickModal.slickGoTo(this.page);
  }

  bclicked(key) {
    if (key=='demo') {
       // this.GenericConfigAction(this.store, '1');
      this.slickModal.slickGoTo(0);
      GenericResetAction(this.store);
      // GenericTopTopAction(this.store, this.cmsg[0]);
      GenericTopTopAction(this.store, this.fheader);
      setTimeout(() => {this.start();}, 5000);
      return;
    }
      this.router.navigate('signin');
  }
  forwardClicked() {
    if (this.mode=='video-first') {
      this.forwardClickedPage1();
      return;
    }
    this.page+=1;
    this.disabled.backward=false;
    this.state='None';
     if (this.page==this.demoPage) {
        GenericTopTopAction(this.store,this.slaw);
    }
    if (this.page==this.size['video-second']) {
      this.page=0;
      this.state='';
      GenericResetAction(this.store);
    }
    const page=this.page+1; // the offset
    if (page>=7) {
        this.GenericConfigAction(this.store, `${page+1}`);
    }
    const base=2;
    if ([base,base+1].includes(this.page)) {
      setTimeout(() => {
        this.state=this.page==base? 'cards0': 'cards1';
      }, 2000);
    } else {
      this.state='';
    }

    GenericTopTopAction(this.store,this.cmsg[this.page]);

    this.slickModal.slickGoTo(this.page);
  }

  backwardClicked() {
    if (this.mode=='video-first') {
      this.backwardClickedPage1();
      return;
    }
    this.page-=1;
    const base=2;
    if ([base,base+1].includes(this.page)) {
      setTimeout(() => {
        this.state=this.page==base? 'cards0': 'cards1';
      }, 2000);
    } else {
      this.state='';
    }

    this.disabled.backward=this.page?false:true;
    // this.page+=1;
     GenericTopTopAction(this.store,this.cmsg[this.page]);
   // this.page-=1;
   this.slickModal.slickGoTo(this.page);
  }

  clicked_scale(button) {

    this.page+=button=='forward'? 1:-1;
    this.disabled.backward=this.page?false: true;
    this.state=this.page==1? "weight": 'None';
    if (this.page==2) {
      this.page=0;
    }
    this.disabled.next=this.page == 1;
    if (this.page==1) {
        this.scale.enable=true;
        GenericTopTopAction(this.store,['Calculate the distance', 'Distance is a difference between: ','low and high counts']);
    } else {
      this.scale.enable=false;
      GenericTopTopAction(this.store, this.flaw);
    }
     this.GenericConfigAction(this.store, `${this.page}`);
     this.slickModal.slickGoTo(this.page);
    return;
  }

  afterChange(event) {
    if(this.mode=='auto' && event.currentSlide==5) {
      GenericTopTopAction(this.store,['Basic strategy @25%', 'Prediction @75%', 'Basic stratgey with Prediction @100%']);
    }
  }


  clicked_cardcount(button) {

    this.page+=button=='forward'? 1:-1;
    this.disabled.backward=this.page?false: true;
    if (this.page==2) {
      this.page=0;
    }
    this.disabled.next=this.page == 1;
    if (this.page==1) {
      GenericTopTopAction(this.store,['Classic high-low method', 'Rule changes','based on the zone']);
    } else {
      this.cells=false;
      GenericTopTopAction(this.store, this.flaw);
    }
     this.GenericConfigAction(this.store, `${this.page}`);
    this.slickModal.slickGoTo(this.page);
    if (this.page) {
      this.cells=true;
    }
    return;
  }

  clicked_video_base(button) {
    if (button=='backward')
       this.backwardClicked();
    else
     this.forwardClicked();
  }
  clicked(button) {
    if (['video-first', 'video-second'].includes(this.mode)) {
      this.clicked_video_base(button);
      return;
    }
    if (this.mode=='video-scale') {
      this.clicked_scale(button);
      return;
    }
    this.clicked_cardcount(button);
  }

  mouse(i,event) {
    this.mouseclass[i]=event;
  }

  ngOnInit():void {
      globalVars['page']='AboutComponent';
      // MobileSendKeyRoute(this.store,[
      //   {key:"backward", disabled: true},
      //   {key:"forward", disabled: true},
      //   {key:"up", disabled: true},
      //   {key:"down", disabled: true}
      // ]);
      for (const e of this.factors) {
        this.mouseclass.push('leave');
      }
      this.top=globalVars['desktop']? 300: 250;
      mainBottomSwitchActionNone(this.store);
      const image=globalVars['image'];
      for (const key in this.images) {
        if (key=='num') continue;
        this.images[key] = this.images[key].map(img=> `${img}${image.dims}.png`);
      }
      this.mobile=globalVars['mobileDevice'];
      if (this.mobile) {
        this.scale.img="200x200";
        this.scale.left=200;
      } else {
        this.scale.img="300x300";
        this.scale.left=300;
      }
      // this._class=this.mobile?"use-all-space":"flex-row margin-side-10p aws-margin-top aws-container-height";
      this.classes.push(this.mobile?"use-all-space":"flex-row margin-side-10p  aws-container-height");
      this.classes.push(this.mobile?"":"margin-side-10p");
        if (this.mode !='auto') {
           BottomAppleModify(this.store, {switch:'apple', enable:['forward', 'backward']});
        }
       this.subscriptions.push(
             this.store
               .select('genericReducer')
               .pipe(filter(event =>event.typeof=== 'GenericDataUnit'))
               .subscribe((data: GenericDataUnit) => {
                 switch(data.action) {
                   case GenericDataUnitEnum.greset : {
                     // this.reset();
                     // alert(`about component recvd global reset`);
                     GenericResetAction(this.store);
                     clearInterval(this.interval);
                     break;
                   }
                   case GenericDataUnitEnum.die : {
                     // this.reset();
                     // alert(`about component recvd global reset`);
                     this.die=true;
                     break;
                   }
                 }
               }
             )
        );
         if (this.mode=='video-first') {
           GenericTopTopAction(this.store, this.fheader);
         }
         // else if (this.mode=='video-second') {
         //   GenericTopTopAction(this.store, this.cmsg[0]);
         // }
         if (this.mode=='auto') {
             this.start();
         }
         this.subscriptions.push(
             this.store
               .select('mobileTopBottom')
               .pipe(filter(data => data.typeof=== 'KeyPressedDm' ))
               .subscribe((data: any) => {
                 if (data.target=='parent') {
                    this.die=true;
                    return;
                 }
            		switch(data.key) {

            		   case 'forward' : {
                        this.clicked_video_base('forward');
            		       break;
            	     } case 'backward' : {
                        this.clicked_video_base('backward');
            		       break;
            	     }
  	          }
               })
           );


         // enable the landingpage-0
  }

  ngOnDestroy():void {
     this.intervalEnabled=false;
    clearInterval(this.interval);
     for(const subscript of this.subscriptions) {
        subscript.unsubscribe();
     }
     if (this.interval) {
       clearInterval(this.interval);
     }
  }
}
